export const listen = {

    created: (el, binding, vnode, prevVnode) => {

        const event = binding.arg;
        const cb = binding.value;

        el.addEventListener(event, cb)

    }
}

export const dispatch = (el, binding, vnode, prevVnode) => {
    const { event, condition, watch: watched } = binding.value;
    const { condition: prevCondition } = binding.oldValue ?? {};

    if (condition === prevCondition) return;

    // vnode.ctx.emit(event) //
    if (condition) {
        const e = new Event(event, { bubbles: true })
        el.dispatchEvent(e);
    }
};
