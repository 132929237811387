// import from this file, because there is no proper export in this package
import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js';

import config from '@/config.js';

// EN imports
import en from '@/locales/en.json';
import commonsEn from '@/locales/en/commons/commons.json';
import navigationEn from '@/locales/en/navigation/navigation.json';
import settingsEn from '@/locales/en/settings/settings.json';
import productGroupEn from '@/locales/en/productGroup/productGroup.json';
import productsEn from '@/locales/en/products/products.json';
import productsAiEn from '@/locales/en/products_ai/products_ai.json';
import pMaxInsightsEn from '@/locales/en/pmax_insights/pmax_insights.json';
import merchantsEn from '@/locales/en/merchants/merchants.json';
import dashboardEn from '@/locales/en/dashboard/dashboard.json';
import crmEn from '@/locales/en/crm/crm.json';
import internalEn from '@/locales/en/internal/internal.json';
import commonsEnCRM from '@/locales/en/crm/commons.json';
import billingUserEn from '@/locales/en/crm/billing_user.json';
import contactInfoEn from '@/locales/en/crm/contact_info.json';
import chargebeeAccountChangesEn from '@/locales/en/crm/chargebee_account_changes.json';
import confirmationModal from '@/locales/en/crm/confirmationModal.json';
import countryCannotRemoveModal from '@/locales/en/crm/country_cannot_remove_modal.json';
import cssSubscribers from '@/locales/en/crm/css_subscribers.json';
import subscriptionsTable from '@/locales/en/crm/subscriptions_table.json';
import selfServiceCrmEn from '@/locales/en/selfServiceCrm/selfServiceCrm.json';
import statusBarsEn from '@/locales/en/statusBars/statusBars.json';
import ActionCenterEn from '@/locales/en/crm/action_center.json';

const datetimeFormats = {
    'en': {
        customDateTime: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'Europe/Amsterdam'
        },
    },
};

export const i18n = createI18n({
    legacy: false, // disable legacy mode to use Composition API
    globalInjection: true, // refer a global scope instance of i18n
    fallbackLocale: 'en-US',
    warnHtmlMessage: false,
    messages: {
        'en-US': en,
        'en': {
            commons: commonsEn,
            navigation: navigationEn,
            settings: settingsEn,
            productGroup: productGroupEn,
            products: productsEn,
            products_ai: productsAiEn,
            pmax_insights: pMaxInsightsEn,
            merchants: merchantsEn,
            dashboard: dashboardEn,
            internal: internalEn,
            statusBars: statusBarsEn,
            crm: {
                all: crmEn,
                action_center: ActionCenterEn,
                billing_user: billingUserEn,
                contact_info: contactInfoEn,
                chargebee_account_changes: chargebeeAccountChangesEn,
                confirmationModal: confirmationModal,
                country_cannot_remove_modal: countryCannotRemoveModal,
                css_subscribers: cssSubscribers,
                subscriptions_table: subscriptionsTable,
                commons: commonsEnCRM
            },
            selfServiceCrm: selfServiceCrmEn,
        },
    },
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    numberFormats: config.numberFormats,
    datetimeFormats,
});
