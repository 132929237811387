/** Array of callbacks for a given attribute name */
interface AttributeCallbacks {
    [key: string]: ((...args: unknown[]) => void)[]
}

/** To track observers per element, we don't need more than one per element */
const observerWeakmap: WeakMap<HTMLElement, MutationObserver> = new WeakMap();
/** To register callbacks by attribute name on an element */
const callbackWeakMap: WeakMap<HTMLElement, AttributeCallbacks> = new WeakMap();

const initCallbackMap = (el: HTMLElement): AttributeCallbacks => {
  if (!callbackWeakMap.has(el)) {
    callbackWeakMap.set(el, {});
  }

  const callbackMap = callbackWeakMap.get(el)!;
  return callbackMap;
};

const initAttributeMap = (attributeCallbackMap: AttributeCallbacks, watchAttributeName: string) => {
  if (!(attributeCallbackMap[watchAttributeName] instanceof Array)) {
    attributeCallbackMap[watchAttributeName] = [];
  }
  return attributeCallbackMap[watchAttributeName];
};

const fireCallbacks = (attributeCallbackMap: AttributeCallbacks, attr: string, args: unknown[]) => {
  const callbacks = attributeCallbackMap[attr];
  if (!(callbacks instanceof Array)) return;
  for (const callback of callbacks) {
    callback(...args);
  }
};

/**
 * Sets an mutation observer on the element. The observer callsback when a given attiribute has changed
 */
export const watch = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mounted: (el, binding, vnode, prevVnode) => {
    const watchAttributeName = binding.arg;
    const callback = binding.value;

    const attributeCallbackMap = initCallbackMap(el);
    // Add Callback
    const callbackArray = initAttributeMap(attributeCallbackMap, watchAttributeName);
    if (callbackArray.indexOf(callback) < 0) {
      callbackArray.push(callback);
    }

    // initial firing of callback
    const value = el.getAttribute(watchAttributeName);
    fireCallbacks(attributeCallbackMap, watchAttributeName, [el, value]);

    if (observerWeakmap.has(el)) return;

    // Add Observer
    const config = { attributes: true, childList: false, subtree: false };

    const mutationObserverCallback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        const attr = mutation.attributeName;
        const value = el.getAttribute(attr);

        fireCallbacks(attributeCallbackMap, attr, [el, value]);
      }
    };
    const mutationObserver = new MutationObserver(mutationObserverCallback);

    mutationObserver.observe(el, config);
    observerWeakmap.set(el, mutationObserver);
  }
};
