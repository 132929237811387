const startShow = async (el, binding, vnode, prevVnode) => {
    el.style.setProperty('transition', 'opacity 1s linear')

    el.style.setProperty('opacity', '0')
    el.style.removeProperty('display')
    await new Promise((resolve) => { setTimeout(resolve, 1) });
    el.style.setProperty('opacity', '100')
}

const stopShow = async (el, binding, vnode, prevVnode) => {
    el.style.setProperty('transition', 'opacity 1s linear')

    el.style.setProperty('opacity', '100')
    el.style.setProperty('opacity', '0')
    if (!Object.hasOwn(binding.modifiers, 'in')) {
        await new Promise((resolve) => { setTimeout(resolve, 1000) });
    }
    el.style.setProperty('display', 'none')
}

export default {
    mounted: async (el, binding, vnode, prevVnode) => {

        const show = binding.value
        if (show) {
            await startShow(el, binding, vnode, prevVnode)
        } else {
            await stopShow(el, binding, vnode, prevVnode)
        }

    },
    updated: async (el, binding, vnode, prevVnode) => {

        if (binding.value === binding.oldValue) return;

        const show = binding.value

        if (show) {
            await startShow(el, binding, vnode, prevVnode);
        } else {
            await stopShow(el, binding, vnode, prevVnode);
        }

    },
    beforeUnmount: async (el, binding, vnode, prevVnode) => {

        await stopShow(el, binding, vnode, prevVnode);

    }
}
