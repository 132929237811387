const evaluateTrigger = (el, condition) => (typeof condition === 'function' ? condition(el) : condition);

const getDefaults = (binding) => {
    const {
        condition = true,
        delay = 0,
        scrollOptions = {},
    } = binding.value || {};

    const assignedScrollOptions = Object.assign(
        {
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
        },
        scrollOptions
    );

    return {
        condition,
        delay,
        scrollOptions: assignedScrollOptions,
    };
};

const dirHandle = (el, binding, vnode, prevVnode) => {
    if (binding.oldValue && (binding.oldValue.condition === binding.value.condition)) return;

    const { condition, delay, scrollOptions} = getDefaults(binding);
    const trigger = evaluateTrigger(el, condition);

    if (trigger) {
        scroll(el, delay, scrollOptions);
    }
};

const eventHandle = (el, delay, scrollOptions) => {
    scroll(el, delay, scrollOptions);
};

const scroll = (target, delay, scrollOptions) => {
    setTimeout(() => {
        target.scrollIntoView(scrollOptions);
    }, delay);
};

export default {
    mounted: (el, binding, vnode, prevVnode) => {
        dirHandle(el, binding, vnode, prevVnode);

        const { condition, delay, scrollOptions } = getDefaults(binding);

        el.addEventListener(
            'scrollIntoView',
            (event) => eventHandle(el, delay, scrollOptions)
        );
    },
    updated: (el, binding, vnode, prevVnode) => {
        dirHandle(el, binding, vnode, prevVnode);
    }

};
