/**
 * Sets visiblility of element. This is so that it's space can be reserved without showing the element
 */
export default (el, binding, vnode, prevVnode) => {
    if (binding.value) {
        el.style.removeProperty('visibility');
    } else {
        el.style.setProperty('visibility', 'hidden');

    }
};
